import React from 'react';
import {useContext, useEffect, useState} from 'react';
import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {isMappableArray, zIndex} from 'aac-components/utils/helpers';
import NavCta from './NavCta';
import SiteSearch from '../SiteSearch';
import NavLink from './NavLink';
import facilityInfo from '../../lib/facility-info.json';
import {NavContext} from '.';
import GetHelpDesktopCta from './GetHelpCtas/GetHelpDesktopCta';
import AppContext from '../AppContext';

const DesktopSubMenu = ({
    show = false,
    menuCta,
    activeSubMenu,
    setActivesubMenu,
    subMenuItems,
    showTestSubNav = true,
}) => {
    const {menu = []} = useContext(NavContext);
    const menuTitle = subMenuItems?.[activeSubMenu]?.title;
    const {asPath} = useRouter();
    const [hoveredItem, setHoveredItem] = useState(null);

    const isSearchTab = menuTitle === 'Search';

    const [subNavOffset, setSubNavOffset] = useState(0);
    useEffect(() => {
        const navHeight =
            document && document.getElementById('main-nav-container')?.clientHeight;

        setSubNavOffset(navHeight);
    }, [asPath]);

    let uniqueFavicons = new Set();
    const {
        sessionStorageState: {
            geolocation: {facility: geolocationSiteKey = '', stateName: state = ''} = {},
        } = {},
    } = ({} = useContext(AppContext));

    const handleMouseEnter = (name, image) => {
        setHoveredItem({
            name,
            image,
        });
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    return (
        <>
            {showTestSubNav ? (
                <GetHelpDesktopCta subNavOffset={subNavOffset} />
            ) : (
                <div
                    className={`secondary-nav-desktop ${show ? 'show' : ''}`}
                    onMouseLeave={() => setActivesubMenu(null)}>
                    {!isSearchTab &&
                        (menuTitle === 'Our Locations' ? (
                            hoveredItem ? (
                                <div>
                                    <div className="hovered-container">
                                        <div className="hovered-image">
                                            <Image
                                                src={hoveredItem.image}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                                alt="American Addiction Centers"
                                                sizes="(max-width: 400px) 100vw"
                                                width="330"
                                                height="330"
                                            />
                                        </div>
                                        <div className="hovered-title">
                                            {hoveredItem.name}
                                        </div>
                                    </div>
                                    <NavCta
                                        activeSecondaryMenuIndex={activeSubMenu}
                                        menuCta={menuCta}
                                        showImage={false}
                                        showCopy={false}
                                    />
                                    <div className="secondary-nav-desktop__outpatient">
                                        <Link
                                            href="/treatment-centers/outpatient-rehab"
                                            title="View Outpatient Locations"
                                            style={{
                                                display: 'block',
                                                textDecoration: 'none',
                                                color: 'var(--interactive-300)',
                                            }}>
                                            View Outpatient Locations
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <NavCta
                                        activeSecondaryMenuIndex={activeSubMenu}
                                        menuCta={menuCta}
                                    />
                                    <div className="secondary-nav-desktop__outpatient">
                                        <Link
                                            href="/treatment-centers/outpatient-rehab"
                                            title="View Outpatient Locations"
                                            style={{
                                                display: 'block',
                                                textDecoration: 'none',
                                                color: 'var(--interactive-300)',
                                            }}>
                                            View Outpatient Locations
                                        </Link>
                                    </div>
                                </div>
                            )
                        ) : (
                            <NavCta
                                activeSecondaryMenuIndex={activeSubMenu}
                                menuCta={menuCta}
                            />
                        ))}
                    <div className="secondary-nav-desktop__menu">
                        {isMappableArray(menu) &&
                            menu?.map((levelOne) => {
                                const title = levelOne?.title;
                                const child_items = levelOne?.child_items;
                                return (
                                    <div
                                        key={title}
                                        className={`sub-menu ${
                                            menuTitle === title ? 'show' : ''
                                        }`}>
                                        <ul className="menu__sections">
                                            {Array.isArray(child_items) &&
                                                child_items.map((section) => {
                                                    const {
                                                        title: sectionTitle = '',
                                                        child_items: sectionLinks = [],
                                                    } = section;
                                                    const columnClass =
                                                        sectionLinks?.length >= 32
                                                            ? 'columns-3'
                                                            : sectionLinks?.length >= 12
                                                            ? 'columns-2'
                                                            : '';

                                                    const showClosest =
                                                        geolocationSiteKey &&
                                                        sectionTitle.includes(state);
                                                    return (
                                                        <li
                                                            key={sectionTitle}
                                                            className={
                                                                showClosest
                                                                    ? 'menu__section-closest-to-you'
                                                                    : 'menu__section'
                                                            }>
                                                            {showClosest && (
                                                                <div className="closest-to-you__text">
                                                                    Closest to You
                                                                </div>
                                                            )}
                                                            <div
                                                                className={
                                                                    showClosest
                                                                        ? 'show-closest-container'
                                                                        : ''
                                                                }>
                                                                <div className="menu__section-title_container">
                                                                    <div
                                                                        className="menu__section--title"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: sectionTitle,
                                                                        }}
                                                                    />
                                                                    {Array.isArray(
                                                                        sectionLinks,
                                                                    ) &&
                                                                        sectionLinks.map(
                                                                            (
                                                                                link,
                                                                                index,
                                                                            ) => {
                                                                                const locationSlug =
                                                                                    link?.url &&
                                                                                    link?.url.replace(
                                                                                        /\/treatment-centers\//g,
                                                                                        '',
                                                                                    );
                                                                                const favicon =
                                                                                    facilityInfo?.[
                                                                                        locationSlug
                                                                                    ]
                                                                                        ?.favicon;

                                                                                // prevent rendering duplicate favicons
                                                                                if (
                                                                                    favicon &&
                                                                                    !uniqueFavicons.has(
                                                                                        favicon,
                                                                                    )
                                                                                ) {
                                                                                    uniqueFavicons.add(
                                                                                        favicon,
                                                                                    );

                                                                                    return (
                                                                                        <div
                                                                                            className="sub-menu__location--favicon"
                                                                                            key={
                                                                                                index
                                                                                            }>
                                                                                            <Image
                                                                                                src={
                                                                                                    favicon
                                                                                                }
                                                                                                width={
                                                                                                    24
                                                                                                }
                                                                                                height={
                                                                                                    24
                                                                                                }
                                                                                                sizes="50vw"
                                                                                                alt="American Addiction Centers"
                                                                                            />
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                return null; // skip rendering if favicon is a duplicate
                                                                            },
                                                                        )}
                                                                </div>
                                                                <ul
                                                                    className={`menu__section--links ${columnClass}`}>
                                                                    {Array.isArray(
                                                                        sectionLinks,
                                                                    ) &&
                                                                        sectionLinks.map(
                                                                            (
                                                                                link,
                                                                                index,
                                                                            ) => {
                                                                                const locationSlug =
                                                                                    link?.url &&
                                                                                    link?.url?.replace(
                                                                                        /\/treatment-centers\//g,
                                                                                        '',
                                                                                    );
                                                                                const image =
                                                                                    facilityInfo?.[
                                                                                        locationSlug
                                                                                    ]
                                                                                        ?.img;

                                                                                return (
                                                                                    <li
                                                                                        key={`${link?.url}-${index}`}
                                                                                        className="menu__section--link"
                                                                                        onMouseEnter={() =>
                                                                                            handleMouseEnter(
                                                                                                link?.title,
                                                                                                image,
                                                                                            )
                                                                                        }
                                                                                        onMouseLeave={
                                                                                            handleMouseLeave
                                                                                        }>
                                                                                        {menu?.title ===
                                                                                        'Our Locations' ? (
                                                                                            <Link
                                                                                                href={
                                                                                                    link?.url
                                                                                                }
                                                                                                title={
                                                                                                    link?.title
                                                                                                }
                                                                                                style={{
                                                                                                    display:
                                                                                                        'block',
                                                                                                    textDecoration:
                                                                                                        'none',
                                                                                                    color: 'var(--tertiary)',
                                                                                                }}>
                                                                                                {image ? (
                                                                                                    <div className="sub-menu__location--image">
                                                                                                        <Image
                                                                                                            src={
                                                                                                                image
                                                                                                            }
                                                                                                            width={
                                                                                                                0
                                                                                                            }
                                                                                                            height={
                                                                                                                0
                                                                                                            }
                                                                                                            sizes="50vw"
                                                                                                            alt="American Addiction Centers"
                                                                                                            style={{
                                                                                                                width: '100%',
                                                                                                                height: '100%',
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div className="sub-menu__location--link">
                                                                                                        <span
                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                __html: link?.title,
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )}
                                                                                            </Link>
                                                                                        ) : (
                                                                                            <NavLink
                                                                                                url={
                                                                                                    link?.url
                                                                                                }
                                                                                                title={
                                                                                                    link?.title
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </li>
                                                                                );
                                                                            },
                                                                        )}
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                );
                            })}
                    </div>
                    {isSearchTab && (
                        <div className="secondary-nav-desktop__search">
                            <SiteSearch />
                        </div>
                    )}

                    <style jsx>
                        {`
                            a {
                                color: var(--tertiary);
                                text-decoration: none;
                            }
                            .secondary-nav-desktop {
                                position: absolute;
                                width: 100%;
                                background: #fff;
                                z-index: ${zIndex('navigation')};
                                max-width: 1380px;
                                margin: 0 auto;
                                left: 0;
                                right: 0;
                                display: grid;
                                grid-template-columns: 1fr 3fr;
                                height: 0;
                                top: ${subNavOffset}px;
                                overflow: hidden;
                                border-radius: 0 0 8px 8px;
                            }
                            @media screen and (max-width: 1380px) {
                                .secondary-nav-desktop {
                                    max-width: 100%;
                                }
                            }
                            .secondary-nav-desktop.show {
                                height: auto;
                                background: var(--gray-100);
                            }
                            @media screen and (max-width: 1380px) {
                                .secondary-nav-desktop.show {
                                    max-height: 75vh;
                                }
                            }
                            .secondary-nav-desktop__sidebar {
                                padding: 24px;
                                border-right: 1px solid var(--primary);
                                background: #fff;
                                border-left: 1px solid var(--gray-300);
                                border-bottom: 1px solid var(--gray-300);
                                border-radius: 0 0 0 8px;
                            }
                            .secondary-nav-desktop__menu {
                                display: block;
                                padding: 16px;
                                background: var(--gray-100);
                                border-right: 1px solid var(--gray-300);
                                border-bottom: 1px solid var(--gray-300);
                                border-radius: 0 0 8px 0;
                            }
                            .menu__sections {
                                display: flex;
                                flex-flow: column wrap;
                                max-height: 70vh;
                                max-width: 100%;
                                overflow: auto;
                            }
                            .menu__section {
                                list-style: none;
                                background: var(--gray-100);
                                border-left: 2px solid var(--primary);
                                max-height: auto;
                                padding: 0 24px 0 8px;
                                margin-bottom: 24px;
                                max-width: fit-content;
                                display: block;
                            }
                            .menu__section-closest-to-you {
                                border: 3px solid var(--warning);
                                border-radius: 8px;
                                list-style: none;
                                margin-bottom: 24px;
                                max-width: 463px;
                            }
                            .menu__section--title {
                                font-size: 18px;
                                font-weight: bold;
                                margin-bottom: 16px;
                                max-width: 350px;
                                padding-right: 15px;
                            }
                            .menu__section--links {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                max-height: 100%;
                            }
                            .menu__section--links.columns-3 {
                                columns: 3;
                                -webkit-columns: 3;
                                -moz-columns: 3;
                            }
                            .menu__section--links.columns-2 {
                                columns: 2;
                                -webkit-columns: 2;
                                -moz-columns: 2;
                            }
                            .menu__section--link {
                                margin: 0 16px 8px 0;
                            }
                            .secondary-nav-desktop__search {
                                border-left: 1px solid var(--primary);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .sub-menu {
                                display: none;
                            }
                            .sub-menu.show {
                                display: block;
                            }
                            .sub-menu__location--image {
                                position: relative;
                                display: block;
                                height: 38px;
                                max-width: fit-content;
                            }
                            .menu__sections::-webkit-scrollbar {
                                width: 5px;
                                height: 5px;
                            }

                            /* Track */
                            .menu__sections::-webkit-scrollbar-track {
                                background: var(--gray-100);
                                border-radius: 2em;
                            }

                            /* Handle */
                            .menu__sections::-webkit-scrollbar-thumb {
                                background: var(--gray-300);
                                border-radius: 2em;
                            }
                            .sub-menu__location--link {
                                font-size: 14px;
                            }
                            .secondary-nav-desktop__outpatient {
                                text-align: center;
                                margin-top: 16px;
                                font-weight: 700;
                            }

                            .menu__section-title_container {
                                display: flex;
                            }
                            .sub-menu__location--favicon {
                                padding-right: 10px;
                            }
                            .closest-to-you__text {
                                background: #ffad0d;
                                font-size: 12px;
                                line-height: 16px;
                                font-weight: bold;
                                width: 120px;
                                height: 24px;
                                text-align: center;
                                padding: 4px 16px;
                                border-top-left-radius: 4px;
                                border-bottom-right-radius: 8px;
                            }
                            .show-closest {
                                padding: 0 16px;
                            }
                            .show-closest-container {
                                padding-left: 15px;
                                padding-top: 15px;
                            }
                            .hovered-container {
                                margin-top: 16px;
                                width: 330px;
                                height: auto;
                            }
                            .hovered-image {
                                position: relative;
                                width: 100%;
                                height: 160px;
                                border-radius: 8px;
                                overflow: hidden;
                                margin-left: 15px;
                            }
                            .hovered-title {
                                margin-top: 16px;
                                margin-left: 15px;
                                font-weight: bold;
                                font-size: 15px;
                                text-align: center;
                                white-space: nowrap;
                            }
                            .nav-cta {
                                width: 330px;
                            }
                        `}
                    </style>
                </div>
            )}
        </>
    );
};
export default DesktopSubMenu;
